<template>
  <!-- 装修类 -->
  <div class="zx_index">
    <Carousel :list="lbImgs" :status="2" class="zx_Carousel"></Carousel>
    <Carousel2 :list="lbImgs2" class="zx_Carousel2"> </Carousel2>
    <div class="zx_index_box1 current_w">
      <div class="box1_title">
        <p>{{ titData.sjTitle }}</p>
        <span>{{ titData.zsjTitle }}</span>
      </div>
      <ul class="box1_list">
        <li
          v-for="(item, idx) in gdList"
          :key="idx"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="li_img">
            <img :src="item.value" alt="" />
          </div>
          <p>{{ item.key }}</p>
        </li>
      </ul>
    </div>
    <div class="zx_index_box2" v-if="Object.keys(dzData).length != 0">
      <h3 style="margin-top:0">{{ titData.dz_title }}</h3>
      <div
        class="box2_dz current_w"
        :style="{ background: `url(${imgData.dz_bg}) no-repeat center` }"
      >
        <ul>
          <li v-for="(item, idx) in dzData.dzList" :key="idx">
            <div
              v-if="idx == 0 || idx == 2"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <img :src="item.value" alt="" />
              <p v-html="item.key"></p>
            </div>
            <div v-else data-aos="fade-down" data-aos-delay="300">
              <p v-html="item.key"></p>
              <img :src="item.value" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <h3>{{ titData.cy_title }}</h3>
      <div
        class="box2_gh current_w"
        :style="{ background: `url(${imgData.dz_img}) no-repeat right bottom` }"
      >
        <ul>
          <li
            v-for="(item, idx) in dzData.cy_list"
            :key="idx"
            :style="{ background: `url(${item.value}) no-repeat left center` }"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <h2>
              {{ item.key }}
            </h2>
            <p>{{ dzData.cy_list2[idx].value }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="zx_index_sj" v-if="Object.keys(dzData).length != 0">
      <h3 style="margin-top:0">{{ titData.dz_title }}</h3>
      <div class="box2_dz current_w">
        <ul>
          <li v-for="(item, idx) in dzData.dzList" :key="idx">
            <div v-if="idx == 0 || idx == 2">
              <img :src="item.value" alt="" />
              <p v-html="item.key"></p>
            </div>
            <div v-else>
              <p v-html="item.key"></p>
              <img :src="item.value" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <h3>{{ titData.cy_title }}</h3>
      <div
        class="box2_gh current_w"
        :style="{ background: `url(${imgData.dz_img}) no-repeat right bottom` }"
      >
        <ul>
          <li
            v-for="(item, idx) in dzData.cy_list"
            :key="idx"
            :style="{ background: `url(${item.value}) no-repeat left center` }"
          >
            <h2>
              {{ item.key }}
            </h2>
            <p>{{ dzData.cy_list2[idx].value }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="zx_index_box3 current_w">
      <h2>{{ titData.al_title }}</h2>
      <div class="box3_nav">
        <ul class="box3_list">
          <li
            v-for="(item, idx) in alList"
            :key="idx"
            @mouseover="alActive = idx"
            :class="[alActive == idx ? 'xz_li' : '']"
          >
            <a :href="`#/zx_gdAl?currentIdx=${idx + 1}`" @click="toLink(79)">
              {{ item.title }}
            </a>
          </li>
        </ul>
        <div
          class="box_img dh-divshow"
          v-for="(item, idx) in alList"
          :key="idx"
          v-show="alActive == idx"
        >
          <a
            :href="
              `#/zx_gdAl?currentIdx=${idx + 1}&currentId=${item.id}`
            "
            @click="toLink(79)"
          >
            <img :src="item.site_images" alt="" />
            <p>{{ item.title }}</p>
          </a>
        </div>
      </div>
    </div>
    <div
      class="zx_index_box4"
      v-if="Object.keys(gdData).length != 0"
      :style="{
        background: `url(${imgData.gd_img})  no-repeat top center`
      }"
    >
      <h2>{{ titData.gd_title }}</h2>
      <ul
        class="box4_list current_w"
        :style="{
          background: `url(${imgData.gd_imgz})  no-repeat center`
        }"
      >
        <li v-for="(item, idx) in gdData.gdList" :key="idx">
          <h4
            :style="{
              background: `url(${item.value}) left center no-repeat`
            }"
          >
            {{ item.key }}
          </h4>
          <p>{{ gdData.gdList2[idx].value }}</p>
        </li>
      </ul>
    </div>
    <!-- <div class="zx_index_box5">
      <h2>{{ titData.sjs_title }}</h2>
      <div class="box5_nav current_w">
        <div
          class="box5_js dh-divshow"
          v-for="(item, idx) in tdList"
          :key="idx"
          v-show="tdActive == idx"
        >
          <div class="box5_js_nr">
            <img :src="item.img" alt="" />
            <div class="nr_text">
              <h4>{{ item.name }}</h4>
              <p v-html="item.html"></p>
              <el-button>联系他</el-button>
            </div>
          </div>
        </div>
        <ul class="box5_list">
          <li
            v-for="(item, idx) in tdList"
            :key="idx"
            @mouseover="tdActive = idx"
            :class="[tdActive == idx ? 'xz_li' : '']"
          >
            <img :src="item.img" alt="" style="width:100%" />
          </li>
        </ul>
      </div>
    </div> -->
    <div class="zx_index_box6">
      <h2>{{ titData.xw_title }}</h2>
      <ul class="box6_list current_w">
        <li v-for="(item, idx) in zxList" :key="idx">
          <h4>{{ item.name }}</h4>
          <a :href="`#/zx_news?currentIdx=${idx + 1}&currentId=${item.id}`" @click="toLink(80)" class="li_nav">
            <img :src="item.news[0].cover_image" alt="" />
            <span>{{ item.news[0].title }}</span>
          </a>
          <a :href="`#/zx_news?currentIdx=${idx + 1}&currentId=${item.id}`" @click="toLink(80)" class="li_nav2">
            <p v-for="(el, val) in item.news" :key="val">{{ el.title }}</p>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="zx_index_box7"
      :style="{
        background: `url(${
          imgData.dp_bg ? imgData.dp_bg : '#212425'
        }) no-repeat top center`
      }"
    >
      <h2>{{ titData.dp_title }}</h2>
      <ul class="box7_list current_w" v-if="Object.keys(dpData).length != 0">
        <li v-for="(item, idx) in dpData.dp_list" :key="idx">
          <template v-if="idx == 1">
            <img :src="item.key" alt="" />
            <img :src="item.value" alt="" style="width:100%" />
            <div class="text" v-html="dpData.dp_list2[idx].value"></div>
          </template>
          <template v-else>
            <img :src="item.value" alt="" style="width:100%" />
            <div class="text" v-html="dpData.dp_list2[idx].value"></div>
            <img :src="item.key" alt="" />
          </template>
        </li>
      </ul>
      <h2>{{ titData.lx_title }}</h2>
      <h4>{{ titData.lx_ztitle }}</h4>
      <div class="lx_btn">联系我们</div>
    </div>
  </div>
</template>

<script>
import { getBasicMsg, getPageMsg, getXwData, getZzCaseList } from '@/api/api'
import Carousel from '@/common/Carousel/Carousel.vue'
import Carousel2 from '@/common/Carousel/zzCarousel1.vue'
export default {
  components: { Carousel, Carousel2 },
  data () {
    return {
      lbImgs: [require('@/assets/image/index/zxlb1.png')],
      lbImgs2: [],
      dzData: {},
      gdList: [],
      alList: [],
      alActive: 0,
      gdData: {},
      rzList: [
        {
          key: '亲临贵府精准测量',
          value:
            '国际视野时尚买手，可以满足您全球化采购单品的需求，知名设计师搭配指导，是整个空间陈设更具艺术性，尺寸更合理，色调更统一'
        },
        {
          key: '专业设计确定风格',
          value:
            '国际视野时尚买手，可以满足您全球化采购单品的需求，知名设计师搭配指导，是整个空间陈设更具艺术性，尺寸更合理，色调更统一'
        },
        {
          key: '专属定制软装配套',
          value:
            '国际视野时尚买手，可以满足您全球化采购单品的需求，知名设计师搭配指导，是整个空间陈设更具艺术性，尺寸更合理，色调更统一'
        },
        {
          key: '配送安装全程无忧',
          value:
            '国际视野时尚买手，可以满足您全球化采购单品的需求，知名设计师搭配指导，是整个空间陈设更具艺术性，尺寸更合理，色调更统一'
        },
        {
          key: '金牌服务售后保障',
          value:
            '国际视野时尚买手，可以满足您全球化采购单品的需求，知名设计师搭配指导，是整个空间陈设更具艺术性，尺寸更合理，色调更统一'
        }
      ],
      // tdList: [
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '刘某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   },
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '周某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   },
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '陈某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   },
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '王某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   },
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '李某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   },
      //   {
      //     img: 'http://img.iapply.cn/a5a3a751f89873700d9cc1b9dc01925d',
      //     name: '周某某',
      //     html:
      //       '专业：室内设计专业<br>个人优势：从业多年、对色彩握较好，能很好地表现出家居环境的内涵，积累了丰富的设计施工经验、服务多套别墅、豪宅并获一致好评。擅于为客户量身定制设计风格，用心设计，让设计融入生活<br>代表作品：湘江壹号、荣悦台 、云鼎梅溪湖、融科东西南海、海尔鸿玺、保利国际<br>擅长风格：新中式风格、北欧风格、港式风格、现代风格、美式风格、欧式风格<br>'
      //   }
      // ],
      tdActive: 0,
      zxList: [],
      dpData: {},
      titData: {},
      imgData: {}
    }
  },
  methods: {
    getData () {
      let that = this
      getBasicMsg(that.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.titData = res.data.data.allTitle
              that.imgData = res.data.data.allImage
              that.lbImgs = res.data.data.顶部.lbList
              that.lbImgs2 = res.data.data.顶部.lbImgs2
              that.gdList = res.data.data.顶部.dbList
              that.dzData = res.data.data.定制
              that.gdData = res.data.data.高端
              that.dpData = res.data.data.单品
            }
          })
          getXwData(item.data.data.project_id).then(res => {
            if (res.data.code == 1) {
              that.zxList = res.data.data
            }
          })
          this.getZzList(item.data.data.project_id)
        }
      })
    },
    getZzList (i) {
      let obj = {
        page: 1,
        pagesize: 9,
        type_id: '',
        project: i
      }
      getZzCaseList(obj).then(res => {
        if (res.data.code == 1) {
          this.alList = res.data.data.list
        }
      })
    },
    toLink(i){
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zx_index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zx_Carousel2 {
    display: none;
  }
  .current_w {
    width: 1200px;
    margin: 0 auto;
  }
  .zx_index_box1 {
    margin: 50px auto;
    .box1_title {
      width: 100%;
      text-align: center;
      p {
        font-size: 36px;
        color: #333;
      }
      span {
        display: block;
        font-size: 18px;
        color: #999;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        padding-top: 10px;
      }
    }
    .box1_list {
      display: flex;
      width: 96%;
      margin: 0 2%;
      margin-top: 30px;
      li {
        width: 11.66%;
        margin-bottom: 10px;
        margin-left: 5%;
        cursor: pointer;
        .li_img {
          height: 54px;
        }
        p {
          width: 96%;
          margin: 0 auto;
          color: #333;
          line-height: 24px;
          text-align: center;
          display: block;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }
  .zx_index_box2,
  .zx_index_sj {
    width: 100%;
    background: #212425;
    padding: 50px 0;
    margin-top: 50px;
    h3 {
      font-size: 36px;
      color: #fff;
      display: block;
      text-align: center;
      margin: 50px 0;
    }
    .box2_dz {
      margin-top: 40px;
      overflow: hidden;
      ul {
        padding: 0 9%;
        display: flex;
        li {
          width: 24%;
          margin-right: 1%;
          p {
            width: 90%;
            margin: 0 5%;
            text-align: center;
            color: #fff;
            font-size: 14px;
            line-height: 1.5;
            margin-top: 15px;
            border: 1px solid #fff;
            padding: 5px 0;
          }
        }
        li:nth-child(2),
        li:nth-child(4) {
          p {
            margin-bottom: 16px;
            margin-top: 0;
          }
        }
      }
    }
    .box2_gh {
      position: relative;
      background-size: 50% !important;
      ul {
        width: 30%;
        li {
          width: 100%;
          margin-top: 30px;
          padding-left: 100px;
          h2 {
            font-size: 18px;
            color: #fff;
            display: block;
            font-weight: bold;
          }
          p {
            font-size: 14px;
            color: #fff;
            margin-top: 10px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .zx_index_sj {
    display: none;
    padding: 1.5rem 0 !important;
    margin-top: 1.5rem !important;
    h3 {
      font-size: 1rem !important;
      margin: 1rem 0 !important;
      margin-top: 2rem !important;
    }
    h3:nth-child(1),
    .box2_dz {
      margin: 0 auto !important;
    }
    .box2_dz ul {
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: calc(50% - 2%) !important;
        margin-top: 1rem !important;
        margin-right: 0 !important;
        p {
          font-size: 0.6rem !important;
        }
      }
    }
    .box2_gh {
      background-size: 100% !important;
      ul {
        width: 100% !important;
        li {
          width: 100%;
          margin-top: 30px;
          padding-left: 0 !important;
          background: none !important;
        }
      }
    }
  }
  .zx_index_box3 {
    padding: 40px 0;
    margin: 30px auto;
    text-align: center;
    h2 {
      margin: 30px 0;
      display: block;
      font-size: 36px;
      color: #333;
      text-align: center;
    }
    .box3_nav {
      display: flex;
      .box3_list {
        width: 8%;
        margin-right: 2%;
        li {
          width: 100%;
          padding: 10px 0;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 12px;
          border: 1px solid #fff;
          font-size: 14px;
          cursor: pointer;
        }
        .xz_li {
          border-color: #333;
        }
      }
      .box_img {
        width: calc(100% - 10%);
        position: relative;
        p {
          background: rgba(0, 0, 0, 0.5);
          text-align: left;
          display: block;
          width: calc(98% - 10px);
          margin: 0 1%;
          line-height: 40px;
          font-size: 14px;
          color: #ccc;
          position: absolute;
          bottom: 0;
          left: 0;
          padding-left: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .zx_index_box4 {
    width: 100%;
    margin-top: 50px;
    padding: 50px 0;
    color: #fff;
    h2 {
      margin: 30px 0;
      display: block;
      font-size: 36px;
      text-align: center;
    }
    .box4_list {
      height: 560px;
      position: relative;
      display: flex;
      li {
        width: 420px;
        position: absolute;
        h4 {
          font-size: 20px;
          color: #fff;
          display: block;
        }
        p {
          font-size: 14px;
          margin-top: 8px;
          line-height: 24px;
          color: #fff;
        }
      }
      li:nth-child(1) {
        left: 10px;
        top: 65px;
      }
      li:nth-child(2) {
        left: 790px;
        top: 65px;
      }
      li:nth-child(3) {
        left: 664px;
        top: 250px;
      }
      li:nth-child(4) {
        left: -10px;
        top: 440px;
      }
      li:nth-child(5) {
        left: 790px;
        top: 440px;
      }
      li:nth-child(1) h4 {
        text-align: right;
        padding-right: 30px;
        background-position: right center !important;
      }
      li:nth-child(2) h4 {
        padding-left: 30px;
      }
      li:nth-child(3) h4 {
        padding-left: 30px;
      }
      li:nth-child(4) h4 {
        text-align: right;
        padding-right: 30px;
        background-position: right center !important;
      }
      li:nth-child(5) h4 {
        padding-left: 30px;
      }
    }
  }
  .zx_index_box5 {
    padding: 40px 0;
    background: url(http://img.iapply.cn/fdd49ff951da351e518ecd2ffe47ac39)
      no-repeat left top;
    color: #fff;
    h2 {
      margin: 30px 0;
      display: block;
      font-size: 36px;
      text-align: center;
    }
    .box5_nav {
      display: flex;
      justify-content: space-between;
      .box5_js {
        width: 700px;
        background: url(http://shsudbjd.moban.vdhui.com/Public/Web/templates/QLC049/images/sjtdleftbg.png)
          no-repeat left top;
        height: 420px;
        .box5_js_nr {
          padding: 40px 0 0 70px;
          display: flex;
          img {
            width: 210px;
            height: 170px;
            margin-top: 10px;
            margin-left: 0;
          }
          .nr_text {
            width: calc(100% - 280px);
            color: #fff;
            h4 {
              font-weight: normal;
              font-size: 24px;
              margin: 0;
            }
            p {
              font-size: 14px;
              line-height: 1.8;
              margin: 15px 0;
            }
          }
        }
      }
      .box5_list {
        width: 350px;
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 0 0 10px 10px;
          width: 162px;
          height: 132px;
          border: 1px solid transparent;
          position: relative;
          cursor: pointer;
          img {
            position: relative;
            z-index: 99;
          }
        }
        .xz_li {
          border-color: #00456b;
        }
        .xz_li::after {
          width: 162px;
          height: 132px;
          content: '';
          background: url(http://shsudbjd.moban.vdhui.com/Public/Web/templates/QLC049/images/sjtdrightbg.png)
            no-repeat center;
          position: absolute;
          display: block;
          left: 10px;
          top: 10px;
          z-index: 1;
        }
      }
    }
  }
  .zx_index_box6 {
    padding: 40px 0;
    background: #f2f2f2;
    h2 {
      margin: 30px 0;
      display: block;
      font-size: 36px;
      text-align: center;
    }
    .box6_list {
      display: flex;
      justify-content: space-between;
      li {
        width: calc(33.3333% - 50px);
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 2px 10px 7px #eee;
        h4 {
          margin: 0 0 15px;
          font-size: 20px;
        }
        a{
          display: block;
        }
        .li_nav {
          width: 100%;
          height: 240px;
          display: inline-block;
          position: relative;
          font-size: 14px;
          img {
            width: 100%;
            height: 100%;
          }
          span {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 40px;
            line-height: 40px;
            padding: 0 5%;
            width: 90%;
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            background: #000 \9;
            filter: alpha(opacity=70);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .li_nav2{
          margin-top: 10px;
          p {
            cursor: pointer;
            font-size: 13px;
            line-height: 2;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .zx_index_box7 {
    width: 100%;
    padding: 40px 0 80px 0;
    color: #fff;
    h2 {
      margin: 30px 0;
      display: block;
      font-size: 36px;
      text-align: center;
    }
    .box7_list {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 30px 0;
      li {
        width: calc(33.3333% - 10px);
        position: relative;
        .text {
          position: absolute;
          top: 0;
          left: 0;
          padding: 0 35px;
          line-height: 2.4;
          font-size: 12px;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      li:nth-child(2) .text {
        top: 50%;
      }
    }
    h4 {
      font-size: 18px;
      color: #fff;
      text-align: center;
      display: block;
      margin: 15px 0;
    }
    .lx_btn {
      color: #fff;
      text-align: center;
      width: 200px;
      padding: 15px 0;
      margin: 0 auto;
      margin-top: 30px;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }
}
img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
</style>
